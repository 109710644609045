import Vue from 'vue'
import router from '../../router/index'

const state = {
  id: null,
  name: null,
  surname: null,
  email: null,
  token: null,
  tokenExpiration: null,
  role: null,
  registrationComplete: null,
  ccRegComplete: null,
  foodytekClientId: null,
  foodytekClientBusinessName: null,
  clientCustomerBusinessName: null
}

const getters = {
  isAuthenticated: (state) => {
    return state.token !== null
  },
  registrationComplete: (state) => {
    return state.registrationComplete
  },
  ccRegComplete: (state) => {
    return state.ccRegComplete
  },
  token: (state) => {
    return state.token
  },
  email: (state) => {
    return state.email
  },
  name: (state) => {
    return state.name
  },
  surname: (state) => {
    return state.surname
  },
  clientId: (state) => {
    return state.foodytekClientId
  },
  clientBusinessName: (state) => {
    return state.foodytekClientBusinessName
  },
  clientCustomerBusinessName: (state) => {
    return state.clientCustomerBusinessName
  },
  role: (state) => {
    return state.role
  },
  isAdminLoggedAsClient: (state) => {
    return state.role === 'admin' && state.foodytekClientId
  },
  isAdmin: (state) => {
    return state.role === 'admin' && !state.foodytekClientId
  },
  isClient: (state) => {
    return state.role === 'foodytek-client-admin' || (state.role === 'admin' && state.foodytekClientId)
  },
  isClientCustomer: (state) => {
    return state.role === 'client-customer-admin'
  },
  tokenExpiration: (state) => {
    return state.tokenExpiration
  }
}

function updateHeaders (token) {
  if (token) {
    const authHeader = 'Bearer ' + token
    Vue.prototype.$http.api.defaults.headers.Authorization = authHeader
    Vue.prototype.$http.auth.defaults.headers.Authorization = authHeader
    Vue.prototype.$http.device.defaults.headers.Authorization = authHeader
    Vue.prototype.$http.payment.defaults.headers.Authorization = authHeader
    Vue.prototype.$http.reports.defaults.headers.Authorization = authHeader
  } else {
    delete Vue.prototype.$http.api.defaults.headers.Authorization
    delete Vue.prototype.$http.auth.defaults.headers.Authorization
    delete Vue.prototype.$http.device.defaults.headers.Authorization
    delete Vue.prototype.$http.payment.defaults.headers.Authorization
    delete Vue.prototype.$http.reports.defaults.headers.Authorization
  }
}

const mutations = {
  loginUser (state, userData) {
    Object.assign(state, userData)
    updateHeaders(state.token)
  },

  logoutUser (state) {
    Object.keys(state).forEach(k => {
      state[ k ] = null
    })
    updateHeaders(state.token)

    window.location = Vue.prototype.$env.isLocalhost ? 'http://localhost:8080/logout'
        : `https://login.${Vue.prototype.$env.domainPostfix}/logout`
  },
  setCCRegComplete: function (state, ccRegComplete) {
    state.ccRegComplete = ccRegComplete
  }
}

function hasAccess (role) {
  const allowedRoles = [ 'admin', 'foodytek-client-admin', 'client-customer-admin' ]
  return allowedRoles.includes(role)
}

const actions = {
  async loginAsClient ({ commit, dispatch, getters }, ftClientId) {
    const res = await Vue.prototype.$http.auth.post('/login/as/' + ftClientId)
    commit('loginUser', res.data)
    router.push('/').then()
  },
  async returnToAdminDashboard ({ dispatch, getters }) {
    dispatch('renewToken', { token: getters.token })
  },
  async renewToken ({ commit, dispatch, getters }, { token, redirectTo }) {
    try {
      const response = await Vue.prototype.$http.auth.get(
          '/login/renew',
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
      )

      if (!hasAccess(response.data.role)) {
        commit('logoutUser')
      } else {
        commit('loginUser', response.data)
        await dispatch('updateAppSettings')

        if (redirectTo) {
          window.location = redirectTo
        } else {
          router.push('/ft-clients').then()
        }
      }
    } catch (err) {
      commit('logoutUser')
    }
  },
  async updateRegComplete ({ commit }) {
    try {
      if (this.getters.isClientCustomer) {
        const res = await Vue.prototype.$http.payment.get('client-customers/reg-complete')
        commit('setCCRegComplete', res.data)
      }
    } catch (err) {
      commit('setCCRegComplete', null)
    }
  }
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
